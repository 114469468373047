import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { title: '申请售后' }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/guide',
    name: 'Guide',
    component: () => import( '../views/guide.vue'),
    meta: { title: '使用指南' }
  },
  //https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx89b93f50251facfb&redirect_uri=http%3A%2F%2Ff366y85065.wicp.vip%3A54971%2Fapi%2Fofficial%2Fmanagement%2FwxPublicLogin&response_type=code&scope=snsapi_userinfo&state=home#wechat_redirect
  {
    path: '/guideList',
    name: 'GuideList',
    component: () => import( '../views/guideList.vue'),
    meta: { title: '使用指南' }
  },
  {
    path: '/guideDetail',
    name: 'GuideDetail',
    component: () => import( '../views/guideDetail.vue'),
    meta: { title: '说明详情' }
  },
  {
    path: '/list',
    name: 'List',
    component: () => import('../views/List.vue'),
    meta: { title: '售后进度' }
  },
  {
    path: '/problem',
    name: 'Problem',
    component: () => import('../views/problem.vue'),
    meta: { title: '常见问题' }
  },
  {
    path: '/problemList',
    name: 'ProblemList',
    component: () => import('../views/problemList.vue'),
    meta: { title: '常见问题' }
  }
  ,
  {
    path: '/detail',
    name: 'Detail',
    component: () => import('../views/detail.vue'),
    meta: { title: '售后详情' }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
