<template>
  <div class="home">
    <div class="title">个人信息</div>
    <van-form class="form-bg">
      <van-field
        v-model="state.name"
        name="name"
        label="姓名"
        placeholder="姓名"
        :rules="[{ required: true, message: '请填写姓名' }]"
      />
      <van-field
        v-model="state.phone"
        type="number"
        name="phone"
        label="联系电话"
        placeholder="电话"
        :rules="[{ required: true, message: '请填写电话' }]"
      />
      <van-field
        v-model="state.proviceName"
        is-link
        readonly
        label="地区"
        placeholder="请选择所在地区"
        @click="show = true"
      />
      <van-popup v-model="show" :style="{ width: '100%' }" position="bottom">
        <van-cascader
          value="proviceName"
          :options="citysList"
          :field-names="proviceList"
          @close="show = false"
          @finish="onFinish"
        />
        <van-field
          v-model="proviceName"
          placeholder="电话"
          :rules="[{ required: true, message: '请填写电话' }]"
        />
      </van-popup>

      <van-field
        v-model="state.detailedAddress"
        name="detailedAddress"
        label="详细地址"
        placeholder="详细地址"
        :rules="[{ required: true, message: '请填写详细地址' }]"
      />
      <div class="title">添加售后产品</div>
      <div @click.stop="isShowBtn" v-if="!buttonShow" class="state-box">
        <div class="state-item">
          <div>产品类型:</div>
          <div>{{ state.productType }}</div>
        </div>
        <div class="state-item">
          <div>产品型号:</div>
          <div>{{ state.productCode }}</div>
        </div>
        <div class="state-item">
          <div>颜色:</div>
          <div>{{ state.productColour }}</div>
        </div>
        <div class="state-item">
          <div>购买渠道:</div>
          <div>{{ state.productBuyPathway }}</div>
        </div>
        <div style="padding-bottom: 0.4rem" class="state-item">
          <div>购入时间:</div>
          <div>
            {{ state.date }}
          </div>
        </div>
        <div style="padding-bottom: 0.4rem" class="state-item">
          <div>故障描述:</div>
          <div style="word-break: break-all; width: 70%">
            {{ state.faultDescribe }}
          </div>
        </div>
        <img @click.stop="clearState" src="../assets/guanbi@2x.png" alt="" />
      </div>
      <div v-if="buttonShow" class="open_btn" @click="openPop">选择产品</div>
      <van-popup
        @click-overlay="clickOverlay"
        class="goods_new"
        v-model="isShow"
      >
        <div class="content">
          <div class="goods_new-top">
            <div>选择产品</div>
            <div @click="onisShow">确定</div>
          </div>
          <van-field
            v-model="state.productType"
            readonly
            clickable
            label="请先选择产品类型"
            placeholder="选择产品类型"
            @click="showProduct = true"
          />
          <van-popup v-model="showProduct" overlay round position="bottom">
            <van-picker
              title="选择产品类型"
              show-toolbar
              :columns="productTypeList"
              confirm-button-text="确定"
              @cancel="onCancelProductType"
              @confirm="onConfirmProductType"
              value-key="name"
            />
          </van-popup>
          <van-field
            v-model="state.productCode"
            readonly
            clickable
            label="请选择产品型号"
            placeholder="选择产品型号"
            @click="showProductCode = true"
          />
          <van-popup v-model="showProductCode" overlay round position="bottom">
            <van-picker
              title="选择产品型号"
              show-toolbar
              :columns="productCodeList"
              confirm-button-text="确定"
              @cancel="onCancelProductCode"
              @confirm="onConfirmProductCode"
              value-key="name"
            />
          </van-popup>
          <van-field
            v-model="state.productColour"
            readonly
            clickable
            label="请选择颜色"
            placeholder="选择颜色"
            @click="showProductColour = true"
          />
          <van-popup
            v-model="showProductColour"
            overlay
            round
            position="bottom"
          >
            <van-picker
              title="选择颜色"
              show-toolbar
              :columns="productCodeColour"
              confirm-button-text="确定"
              @cancel="onCancelProductColour"
              @confirm="onConfirmProductColour"
              value-key="name"
            />
          </van-popup>
          <!-- <van-field
            v-model="state.productSerialNumber"
            name="productSerialNumber"
            label="产品SN码"
            placeholder="请输入产品SN码"
            :rules="[{ required: true, message: '请填写产品SN码' }]"
          /> -->
          <van-cell
            title="选择购入时间"
            :value="state.date"
            @click="dateShow = true"
          />
          <van-calendar
            v-model="dateShow"
            :min-date="minDate" :max-date="maxDate"
            @confirm="dateOnConfirm"
            style="height: 100%"
          />
          <van-field
            v-model="state.productBuyPathway"
            readonly
            clickable
            poppable="false"
            label="请选择购买渠道"
            placeholder="选择购买渠道"
            @click="showProductBuyPathway = true"
          />
          <van-popup
            v-model="showProductBuyPathway"
            overlay
            round
            position="bottom"
          >
            <van-picker
              title="选择购买渠道"
              show-toolbar
              :columns="productCodeBuyPathway"
              confirm-button-text="确定"
              @cancel="onCancelProductBuyPathway"
              @confirm="onConfirmProductBuyPathway"
              value-key="name"
            />
          </van-popup>
          <van-field
            v-model="state.faultDescribe"
            rows="4"
            autosize
            label="故障描述"
            type="textarea"
            maxlength="200"
            placeholder="请描述您遇到的问题"
            show-word-limit
            :rules="[{ required: true, message: '请填写故障描述' }]"
          />
        </div>
      </van-popup>
      <div class="title">上传购买凭证</div>
      <!-- <van-uploader
        class="img_upload"
        :after-read="afterRead"
        :before-delete = "afterdelete"
        v-model="fileList"
        ResultType="file"
      ></van-uploader> -->
      <div class="all_img">
        <span class="up_span" v-for="(item, i) in filePathList" :key="i">
          <img class="up_image" :src="item" alt="图片" />
          <van-icon name="close" @click="afterdelete(i)" class="delte" />
        </span>
      </div>
      <van-uploader class="img_upload" :after-read="afterRead" />

      <van-row>
        <van-col class="col-font1" span="8">可选凭证类型：</van-col>
        <van-col class="col-font2" span="15"
          >1.销售发票 2.产品保修卡 3.包装盒序列 4.电商购买凭证</van-col
        >
      </van-row>
      <div class="bottom-button">
        <van-button
          color="#FF7775"
          size="large"
          square
          block
          type="primary"
          @click="onSubmit"
        >
          确认申请售后服务
        </van-button>
      </div>
    </van-form>
    <img class="bgImg" src="../assets/walking-dog@2x.png" alt="" />
  </div>
</template>

<script>
import {
  Form,
  Field,
  Area,
  Popup,
  Cell,
  Toast,
  Picker,
  Dialog,
  ActionSheet,
  Overlay,
  Notify,
  Uploader,
  Cascader,
  Col,
  Row,
  Icon,
  Calendar,
} from "vant";
// import { Image as VanImage } from 'vant';
import { wxPublicAuth } from "../api/home";
import {
  getDictByCode,
  createProductReturn,
  getProvice,
  uploadImg,
} from "../api/home";
export default {
  name: "Home",
  components: {
    "van-form": Form,
    "van-field": Field,
    "van-area": Area,
    "van-popup": Popup,
    "van-cell": Cell,
    "van-picker": Picker,
    "van-dialog": Dialog,
    "van-action-sheet": ActionSheet,
    "van-overlay": Overlay,
    "van-uploader": Uploader,
    "van-cascader": Cascader,
    "van-row": Row,
    "van-col": Col,
    "van-icon": Icon,
    "van-calendar": Calendar,
  },
  data() {
    return {
      buttonShow: true,

      dateShow: false,
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(),
      state: {
        username: "",
        password: "",
        proviceName: "选择所在的地区",
        area: "",
        detailedAddress: "",
        productType: "",
        productTypeId: "",
        productCode: "",
        productCodeId: "",
        productColour: "",
        productColourId: "",
        productBuyPathway: "",
        productBuyPathwayId: "",
        productSerialNumber: "",
        faultDescribe: "",
        date: "",
      },
      showProduct: false,
      showProductCode: false,
      showProductColour: false,
      showProductBuyPathway: false,
      show: false,
      productTypeList: [],
      productCodeList: [],
      productCodeColour: [],
      productCodeBuyPathway: [],
      isShow: false,
      fileList: [],
      filePathList: [],
      proviceList: {
        text: "abbreviation",
        value: "code",
        children: "administrativeDivisions",
      },
      citysList: [],
      proviceName: "",
    };
  },
  created() {
    this.getCitys();
    this.setCrumbs();
    console.log("created");
    this.getDictByCode();
    var url = window.location.href;
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
      var strs = url.split("?");
      var strs1 = strs[1].split("&");
      console.log("strs", strs);
      for (var i = 0; i < strs1.length; i++) {
        theRequest[strs1[i].split("=")[0]] = unescape(strs1[i].split("=")[1]);
      }
    }
    if (theRequest.token) {
      localStorage.setItem("token", theRequest.token);
      localStorage.setItem("openId", theRequest.openId);
    } else {
      if (localStorage.getItem("token") == null) {
        this.wxPublicAuth();
      }
    }
    console.log(theRequest);
  },
  methods: {
    //选择购入时间
    dateOnConfirm(date) {
      console.log("购入时间", date);
      this.dateShow = false;
      this.state.date = this.formatDate(date);
    },
    formatDate(date) {
      return `${date.getFullYear()}/${
        date.getMonth() + 1
      }/${date.getDate()}`;
    },
    afterdelete(e) {
      console.log(e, this.filePathList);
      let list = [];
      for (let i = 0; i < this.filePathList.length; i++) {
        if (e == i) {
          console.log(i);
        } else {
          list.push(this.filePathList[i]);
        }
      }
      console.log(list);
      this.filePathList = list;
    },
    wxPublicAuth() {
      wxPublicAuth({
        page: "home",
      })
        .then((res) => {
          console.log(res);
          window.location.href = res.data;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    clickOverlay() {
      this.state.faultDescribe = "";
      this.state.productBuyPathway = "";
      this.state.productBuyPathwayId = "";
      this.state.productCode = "";
      this.state.productCodeId = "";
      this.state.productColour = "";
      this.state.productColourId = "";
      this.state.productType = "";
      this.state.productTypeId = "";
      this.buttonShow = true;
    },
    clearState() {
      console.log(this.state);
      this.state.faultDescribe = "";
      this.state.productBuyPathway = "";
      this.state.productBuyPathwayId = "";
      this.state.productCode = "";
      this.state.productCodeId = "";
      this.state.productColour = "";
      this.state.productColourId = "";
      this.state.productType = "";
      this.state.productTypeId = "";
      this.buttonShow = true;
    },
    onisShow() {
      console.log("确定");
      this.isShow = false;

      if (
        this.state.productType &&
        this.state.productCode &&
        this.state.productColour &&
        this.state.productBuyPathway &&
        this.state.productType
      ) {
        this.buttonShow = false;
      }
    },
    //获取省市区
    getCitys() {
      getProvice({})
        .then((res) => {
          console.log(res.data);
          this.citysList = res.data;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    // 完成选择地区
    onFinish(e) {
      console.log(e);
      if (
        e.selectedOptions[0].abbreviation == e.selectedOptions[1].abbreviation
      ) {
        this.state.proviceName =
          e.selectedOptions[0].name + "," + e.selectedOptions[2].name;
      } else {
        this.state.proviceName =
          e.selectedOptions[0].name +
          "," +
          e.selectedOptions[1].name +
          "," +
          e.selectedOptions[2].name;
      }
      this.state.area = e.selectedOptions[2].name;
      this.state.city = e.selectedOptions[1].name;
      this.state.province = e.selectedOptions[0].name;
      // console.log(this.proviceName);
      this.show = false;
    },

    getDictByCode() {
      getDictByCode({
        code: 6000,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.productTypeList = res.data;
        }
      });

      getDictByCode({
        code: 7000,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.productCodeBuyPathway = res.data;
        }
      });
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    afterRead(file) {
      console.log(file);
      var files = this.dataURLtoBlob(file.content);
      console.log(files);
      let formdata = new FormData();
      let _filename = new Date().getTime() + ".jpg";
      formdata.append("flag", "3001");
      formdata.append("file", files, _filename);
      console.log(formdata, this);
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
        duration: 0,
      });
      this.$instance.post("/api/official/file/upload", formdata).then((res) => {
        console.log(res);
        Toast.clear();
        if (res.status == 200) {
          if (res.data.code == 200) {
            Toast("上传成功");
            this.filePathList.push(res.data.data);
          } else {
            Toast(res.data.msg);
          }
        }
      });
    },
    openPop() {
      this.isShow = true;
    },
    setCrumbs() {},
    onSubmit() {
      console.log(this.state);
      if (this.state.area == "选择所在的地区" || this.state.area == "") {
        Dialog.alert({
          message: "请选择所在的地区",
          theme: "round-button",
        }).then(() => {});
        return false;
      }
      if (this.state.productTypeId == "") {
        Dialog.alert({
          message: "请选择产品类型",
          theme: "round-button",
        }).then(() => {});
        return false;
      }
      if (this.state.productCodeId == "") {
        Dialog.alert({
          message: "请选择产品型号",
          theme: "round-button",
        }).then(() => {});
        return false;
      }
      if (this.state.productColourId == "") {
        Dialog.alert({
          message: "请选择颜色",
          theme: "round-button",
        }).then(() => {});
        return false;
      }
      if (this.state.date == "") {
        Dialog.alert({
          message: "请选择购入时间",
          theme: "round-button",
        }).then(() => {});
        return false;
      }
      if (this.state.productBuyPathwayId == "") {
        Dialog.alert({
          message: "请选择购买渠道",
          theme: "round-button",
        }).then(() => {});
        return false;
      }
      console.log(this.state.phone[0] != 1);
      if (this.state.phone.length != 11 || this.state.phone[0] != 1) {
        Dialog.alert({
          message: "请填写正确手机号",
          theme: "round-button",
        }).then(() => {});
        return false;
      }
      if (this.filePathList.length == 0) {
        Dialog.alert({
          message: "购买凭证必须上传",
          theme: "round-button",
        }).then(() => {});
        return false;
      }
      if (this.state.faultDescribe == "") {
        Dialog.alert({
          message: "故障描述必须填写",
          theme: "round-button",
        }).then(() => {});
        return false;
      }
      console.log("都输入了", this.state.faultDescribe);
      var area = this.state.area.split(",");
      console.log(this.fileList);
      let prof = JSON.stringify(this.filePathList);
      console.log(prof, typeof prof);
      createProductReturn({
        buyTime: this.state.date + " 00:00:00",
        area: this.state.area,
        buyPathway: this.state.productBuyPathwayId,
        city: this.state.city,
        colour: this.state.productColourId,
        detailedAddress: this.state.detailedAddress,
        faultDescribe: this.state.faultDescribe,
        name: this.state.name,
        phone: this.state.phone,
        productCode: this.state.productCodeId,
        productSerialNumber: this.state.productSerialNumber,
        productType: this.state.productTypeId,
        province: this.state.province,
        proof: prof,
        openId: localStorage.getItem("openId"),
      }).then((res) => {
        console.log(res);

        if (res.code == 200) {
          Toast.success("申请成功");
          // Notify({ type: "primary", message: "申请成功" });
          var that = this;
          var timer = setTimeout(function () {
            that.$router.push({
              path: "/list",
            });
            clearTimeout(timer);
          }, 1500);
        } else {
          Toast.fail("申请失败");
          // Notify({ type: "warning", message: res.msg });
        }
      });
    },
    cancelArea() {
      this.show = false;
    },
    confirmArea(list) {
      console.log(list);

      if (list[0].code == "" && !list[0].code) {
        Toast("请选择省份");
        return false;
      }
      if (list[1].code == "" && !list[1].code) {
        Toast("请选择城市");
        return false;
      }
      if (list[2].code == "" && !list[2].code) {
        Toast("请选择区/县");
        return false;
      }
      this.state.area = list[0].name + "," + list[1].name + "," + list[2].name;
      this.show = false;
    },
    showPopup() {
      this.show = true;
    },
    onCancelProductType() {
      this.showProduct = false;
    },
    isShowBtn() {
      console.log("111");
      this.isShow = true;
    },
    onConfirmProductType(e) {
      console.log(e);
      console.log("this", this);
      this.state.productType = e.name;
      this.state.productTypeId = e.code;
      this.state.productCode = "";
      this.state.productCodeId = "";
      this.state.productColour = "";
      this.state.productColourId = "";
      this.showProduct = false;
      this.productCodeList = [];
      this.productCodeColour = [];
      getDictByCode({
        code: e.code,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.productCodeList = res.data;
        }
      });
    },
    onCancelProductCode() {
      this.showProductCode = false;
    },
    onConfirmProductCode(e) {
      console.log(e);
      this.state.productCode = e.name;
      this.state.productCodeId = e.code;
      this.state.productColour = "";
      this.state.productColourId = "";
      this.showProductCode = false;
      getDictByCode({
        code: e.code,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.productCodeColour = res.data;
        }
      });
    },
    onCancelProductColour() {
      this.showProductColour = false;
    },
    onConfirmProductColour(e) {
      console.log(e);
      this.state.productColour = e.name;
      this.state.productColourId = e.code;
      this.showProductColour = false;
    },
    onCancelProductBuyPathway() {
      this.showProductBuyPathway = false;
    },
    onConfirmProductBuyPathway(e) {
      console.log(e);
      this.state.productBuyPathway = e.name;
      this.state.productBuyPathwayId = e.code;
      this.showProductBuyPathway = false;
    },
  },
};
</script>
<style scoped>
.all_img {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.up_span {
  position: relative;
  display: block;
  width: 5rem;
  height: 5rem;
  margin-left: 0.3rem;
  background-color: #fff;
  float: left;
}
.up_image {
  position: absolute;
  z-index: 1;
  width: 5rem;
  height: 5rem;
}
.delte {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  background-color: rgba(51, 51, 51);
  /* color: #fff; */
  border-radius: 50%;
}
.state-box {
  display: flex;
  flex-direction: column;
  border: 1px solid #b3b4b8;
  border-radius: 5px;
  position: relative;
  margin-top: 2.3rem;
}
.state-item {
  display: flex;
  padding-top: 0.5rem;
  margin: 0 10px;
}
.state-item > div:nth-child(1) {
  margin-right: 8px;
}
.state-box img {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 22px;
  height: 22px;
}
.state-item > div:nth-child(1) {
  color: #777777;
}
.goods_new-top {
  display: flex;
  justify-content: space-between;
  color: #333333;
  font-size: 1.125rem;
}
.goods_new-top > div:nth-child(1) {
  font-size: 1.3rem;
}
.goods_new-top > div:nth-child(2) {
  color: #ff5b5b;
}
.bottom-button {
  margin-top: 1rem;
}
.col-font1 {
  font-size: 0.75rem;
  color: #ff5b5b;
}
.col-font2 {
  font-size: 0.75rem;
  color: #777777;
}
.home {
  font-family: fz;
  margin: 0 1.375rem 0 1.25rem;
  position: relative;
}
.bgImg {
  position: absolute;
  bottom: 3.3rem;
  right: 0;
  widows: 10.18rem;
  height: 10.75rem;
}
.title {
  font-size: 1.125rem;
  color: #333333;
}
.van-field__label {
  color: #777777;
  font-size: 0.9375rem;
}
.goods_new .van-field__label {
  color: #777777;
  width: 8rem;
}
.van-cell {
  padding: 10px 0;
}
textarea {
  border: 1px solid #666;
}
.goods_new {
  width: 84%;
  height: 30rem;
  padding: 1.43rem 0.81rem 0;
}
.open_btn {
  height: 2.75rem;
  border-radius: 0.3125rem;
  border: 1px solid #b3b4b8;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b3b4b8;
  margin-top: 2.3rem;
  z-index: 9999 !important;
}
.title {
  margin-top: 2rem;
}
.img_upload {
  margin-top: 1.5rem;
  width: 100%;
}
.van-uploader__wrapper {
  display: flex;
  justify-content: center;
}
.van-button {
  border-radius: 5px;
}
</style>
