/*
 * @Author: your name
 * @Date: 2021-08-07 16:53:16
 * @LastEditTime: 2021-09-17 15:51:10
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \pwh5\src\utils\http.js
 */
import axios from 'axios';
import Vue from 'vue'
// import {
// 	Loading,
// 	Message
// } from 'element-ui';
var instance = axios.create({
	baseURL:"http://public.pwsound.top/web",
	timeout:15000,
	headers:{'Content-Type': 'multipart/form-data'}
})
Vue.config.productionTip = false
Vue.prototype.$instance = instance
import router from '../router/index.js';
// 测试环境
if (process.env.NODE_ENV == 'development') {
	//  axios.defaults.baseURL = 'http://192.168.8.111:9087';
	axios.defaults.baseURL = 'http://public.pwsound.top/web'; 
}
// 正式环境
else if (process.env.NODE_ENV == 'production') {
	axios.defaults.baseURL = 'http://public.pwsound.top/web';
}
const service = axios.create({
	timeout: 15000 // 请求超时时间
})
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

let loadingInstance;

service.interceptors.request.use(function(config) {
	// loadingInstance = Loading.service({
	// 	lock: true,
	// 	text: '加载中……',
	// 	spinner: 'el-icon-loading',
	// 	background: 'rgba(0, 0, 0, 0.7)'
	// });
	// config.headers['token'] = "eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyLmN1cnJlbnQudG9rZW4udmFsaWQiOjcyMCwic3ViIjoiNzUxMGU2NTk3YjFkNGU4N2E3NDEzZTM3MTYyYWIwZWYiLCJ1c2VyLmN1cnJlbnQuaWQiOiI0NzcwMzFmYTVlZDQ3Mjg0NDZjODQ2NWQyOTVlMDlhOCIsInVzZXIuY3VycmVudC50eXBlIjoidXNlci5jdXJyZW50LnR5cGUuYXBpIiwianRpIjoicGV0d2FudDpyZWRpczp0b2tlbjp1c2VyLmN1cnJlbnQudHlwZS5hcGlfNDc3MDMxZmE1ZWQ0NzI4NDQ2Yzg0NjVkMjk1ZTA5YTgifQ.sWDhChWrQA7tREdMbZI1ikr3RWjWv02U3N4YkTk22RciJE38aM8aB_KVvQO9AAtZpSdPOPEk7v1wMGeeDNuE7w";
	config.headers['token'] = localStorage.getItem('token');
	return config;
})

// 响应拦截  401 token过期处理
service.interceptors.response.use(
	function(response) {
		// loadingInstance.close();
		if (response.status == 200) {
			if (response.data.code == 200) {
				return response.data;
			} else if (response.data.code == 401) {
				// localStorage.clear()
				return response.data;
				// return router.push('/login');
			} else {
				return response.data;
				// Message.error(response.data.msg);
				// return Promise.reject(error);
			}
		}
		// Message.error(response.status);
		return Promise.reject(error);
	},
	//接口错误状态处理，也就是说无响应时的处理
	function(error) {
		// loadingInstance.close();
		// Message.error('网络连接失败');
		return Promise.reject(error);
	}
);

export default service;
