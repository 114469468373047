import http from '../utils/http';
//根据code获取下级字典

export function getDictByCode(data) {
  return http({
    url: '/api/official/dataDictionaries/getDictByCode',
    method: 'get',
    params: data
  })
}


//添加产品售后信息
// export function createProductReturn(data) {
//   return http({
//     url: '/api/official/productReturn/createProductReturn',
//     method: 'post',
//     params: data
//   })

  
// }
export const createProductReturn = params => http.post('/api/official/productReturn/createProductReturn', params)



//产品售后列表信息
export function getProductReturnList(data) {
  return http({
    url: '/api/official/productReturn/getProductReturnList',
    method: 'get',
    params: data
  })
}

//获取寄件地址
export function getAdress(data) {
  return http({
    url: '/api/official/productReturn/getProductReturnList',
    method: 'get',
    params: data
  })
}


// 填写物流单号
export function lookMallingAddress(data) {
  return http({
    url: '/api/official/productReturn/lookMallingAddress',
    method: 'put',
    params: data
  })
}

// 确认收货
export function confirmProductReturn(data) {
  return http({
    url: '/api/official/productReturn/confirmProductReturn',
    method: 'put',
    params: data
  })
}


// 取消售后
export function cancelProductReturn(data) {
  return http({
    url: '/api/official/productReturn/cancelProductReturn',
    method: 'put',
    params: data
  })
}

//获取省市区/api/official/administrative/division/administrativeInfo
export function getProvice(data){
  return http({
    url: '/api/official/administrative/division/administrativeInfo',
    method: 'get',
    params: data
  })
} 


//根据id获取产品售后详情
export function getProductReturnById(data){
  return http({
    url: '/api/official/productReturn/getProductReturnById',
    method: 'get',
    params: data
  })
} 

// 微信公众号授权
export function wxPublicAuth(data){
  return http({
    url: '/api/official/management/wxPublicAuth',
    method: 'post',
    params: data
  })
} 

// 支付服务金额
export function payServerFee(data){
  return http({
    url: '/api/official/productReturn/payServerFee',
    method: 'post',
    params: data
  })
} 


// 微信公众号API授权
export function wxPublicImpower(data){
  return http({
    url: '/api/official/management/wxPublicImpower',
    method: 'get',
    params: data
  })
} 

export function companyAddress(data){
  return http({
    url: '/api/official/productReturn/companyAddress',
    method: 'get',
    params: data
  })
} 