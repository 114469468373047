import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import { Button } from 'vant';

Vue.use(Button);
//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
 
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
